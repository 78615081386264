@use '@/scss' as *;

.button {
  position: relative;
  border-radius: 50% !important;
  padding: 2.3em !important;
  overflow: visible !important;
}

.backgroundColorPaper {
  background-color: $background;
  @include elevationx8();
}

.countBadge {
  position: absolute;
  transform: translateX(50%);
  border-radius: 0.8rem;
  padding: 0 4px;
  height: 1.2rem;
  color: $backgroundPaper;
  background-color: $success;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  @include captionSecond-typeface();

  @include media(tablet) {
    top: 0.2rem;
    right: 0.9rem;
  }
}
