@mixin elevationx12() {
  box-shadow:
    0px 7px 8px 0px rgba(0, 0, 0, 0.1),
    0px 5px 22px 0px rgba(0, 0, 0, 0.06),
    0px 12px 17px 0px rgba(0, 0, 0, 0.07);
}

@mixin elevationx8() {
  box-shadow:
    0px 5px 5px rgba(0, 0, 0, 0.1),
    0px 3px 14px rgba(0, 0, 0, 0.06);
}

@mixin elevationx6() {
  box-shadow:
    0px 6px 10px rgba(0, 0, 0, 0.07),
    0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
}

@mixin elevationx4() {
  box-shadow:
    0px 4px 5px rgba(0, 0, 0, 0.07),
    0px 1px 10px rgba(0, 0, 0, 0.06),
    0px 2px 4px rgba(0, 0, 0, 0.1);
}

@mixin elevationx2() {
  box-shadow:
    0px 2px 5px rgba(0, 0, 0, 0.07),
    0px 1px 4px rgba(0, 0, 0, 0.06),
    0px 1px 2px rgba(0, 0, 0, 0.1);
}

@mixin elevationx24() {
  box-shadow:
    0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 11px 15px rgba(0, 0, 0, 0.1);
}

@mixin elevationx06dp() {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.04);
}

@mixin elevationSecondary() {
  box-shadow: 0px 0px 8px 0px rgba(20, 190, 171, 0.3);
}
