@use '../variables/mediaqueries' as *;

// @include media(tablet){}
@mixin media($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/* DEVICE TYPE AND PWA MEDIAQUERIES */
/* Replace "standalone" depending on your manifest.json display mode value */

/* All installed PWAs */
// @include isPwa(){}
@mixin isPwa() {
  @media (display-mode: standalone) {
    @content;
  }
}

/* All mobile and tablet devices - "touch targets" */
// @include isMobileDevice(){} || @include isMobileDevice('android'){}
// https://stackoverflow.com/questions/12469875/how-to-code-css-media-queries-targeting-all-mobile-devices-and-tablets/
@mixin isMobileDevice($type) {
  @media (hover: none), (hover: on-demand) {
    @if $type == 'ios' {
      @supports (-webkit-overflow-scrolling: touch) {
        /* Apple devices */
        @content;
      }
    } @else if $type == 'android' {
      @supports not (-webkit-overflow-scrolling: touch) {
        /* non-Apple devices */
        @content;
      }
    } @else {
      @content;
    }
  }
}
