// base
$primary: #631ffe;
$secondary: #01d5bf;
$tertiary: #ffffff;
$accent: #ffe35e;

// variants
$primaryx025: #f7f5fe;
$primaryx050: #ece4ff;
$primaryx100: #d0bcff;
$primaryx200: #b18fff;
$primaryx300: #9262fe;
$primaryx400: #7a41fe;
$primaryUltraLight: #f7f5fe;

$secondaryx050: #e1faf7;

// state
$success: #01d5bf;
$error: #f78585;

// text
$textOnBackground: rgb(24, 8, 64);
$textOnBackgroundMedium: rgba(24, 8, 64, 0.68);
$textOnBackgroundLight: rgba(24, 8, 64, 0.38);
$textOnBackgroundUltraLight: rgba(24, 8, 64, 0.18);

$textOnPrimary: #ffffff;
$textOnPrimaryMedium: rgba(255, 255, 255, 0.87);
$textOnPrimaryLight: rgba(255, 255, 255, 0.68);
$textOnPrimaryUltraLight: rgba(255, 255, 255, 0.18);

$textOnSecondary: #180840;
$textOnTertiary: #631ffe;
$textOnAccent: #180840;

// background
$background: #f6f6f6;
$backgroundPaper: #ffffff;
$backgroundPaperTranslucid: rgba(255, 255, 255, 0.7);
$backgroundDark: #efefef;
$modalBackground: rgba(24, 8, 64, 0.68);
$buttonOnPressBackground: #ece4ff;

// shadow
$overlay: rgba(24, 8, 64, 0.68);
$blackShadowLight: rgba(0, 0, 0, 0.2);
$transparent: rgba(255, 255, 255, 0);

// components
$caret: #2d00f7;
$divider: #efefef;
$placeholder: #b1b1b1;
$placeholderHighlight: rgba(255, 255, 255, 0.38);
$selection: #2d00f7;
$inputBackground: #e8e6ec;

// Other
$black: #000000;
$neutralx100: #d1ced9;
