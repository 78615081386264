@mixin flex($justify: flex-start, $align: center, $direction: row, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin border($px: 1px, $style: solid, $color, $pxRadius: 0) {
  border: $px $style $color;
  @if $pxRadius {
    border-radius: $pxRadius;
  }
}

@mixin ellipsis($font-size: 1em, $line-height: 1.2, $lines-to-show: 1) {
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  height: $font-size * $line-height * $lines-to-show; // Fallback for non-webkit
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@mixin font-style($weight: normal, $height: normal, $style: normal, $stretch: normal, $spacing: normal) {
  font-weight: $weight;
  line-height: $height;
  font-style: $style;
  font-stretch: $stretch;
  letter-spacing: $spacing;
}

@mixin animate($name, $duration: 1s, $iteration: infinite, $delay: 0) {
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: $iteration;
  animation-delay: $delay;
}

@mixin hide-scrollbar {
  // Chromium
  &::-webkit-scrollbar {
    display: none;
  }
  // Mozilla
  scrollbar-width: none;
}

@mixin filterPNGToPrimary {
  filter: brightness(1%) invert(12%) sepia(91%) saturate(6655%) hue-rotate(262deg) brightness(99%)
    contrast(101%);
}

@mixin resetInputStyles {
  border: none;
  border-radius: 0;
  padding: 0;
  background-color: inherit;
}

@mixin fullVHeight {
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
}
