@use '../tools/mediaqueries' as *;
@use '../tools/functions' as *;
@use '../variables/colors' as *;

//=====================
//       Headings
//=====================

@mixin heading3-typeface() {
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  font-size: px-to-rem(36px);
  font-weight: 700;
  line-height: px-to-rem(72px);
  letter-spacing: px-to-rem(0.5px);
}

@mixin heading4-typeface() {
  font-size: px-to-rem(34px);
  font-weight: 700;
  line-height: px-to-rem(36px);
}

@mixin heading5-typeface() {
  font-size: px-to-rem(24px);
  font-weight: 700;
  line-height: px-to-rem(24px);
  letter-spacing: px-to-rem(0.18px);
}

@mixin heading6-typeface() {
  font-size: px-to-rem(20px);
  line-height: px-to-rem(24px);
  font-weight: 700;
  letter-spacing: px-to-rem(0.15px);
}

@mixin heading7-typeface() {
  font-size: px-to-rem(18px);
  line-height: px-to-rem(24px);
  font-weight: 700;
  letter-spacing: px-to-rem(0.15px);
}

@mixin extendedFontTitle-typeface() {
  font-family: var(--font-family-secondary);
  font-size: px-to-rem(32px);
  line-height: px-to-rem(40px);
  letter-spacing: px-to-rem(-0.5px);
}

//=====================
//       Text
//=====================

@mixin body-typeface() {
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  letter-spacing: px-to-rem(0.5px);
}

@mixin bodySecond-typeface() {
  font-size: px-to-rem(14px);
  line-height: px-to-rem(20px);
  letter-spacing: px-to-rem(0.25px);
}

@mixin caption-typeface() {
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(0.4px);
}

@mixin errorCaption-typeface() {
  color: $error;
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(0.4px);
}

@mixin captionSecond-typeface() {
  font-size: px-to-rem(10px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(0.2px);
}

@mixin subtitle-typeface() {
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  letter-spacing: px-to-rem(0.15px);
}

@mixin subtitle-typeface-thin() {
  font-size: px-to-rem(16px);
  font-weight: 100;
  line-height: px-to-rem(24px);
  letter-spacing: px-to-rem(0.15px);
}

@mixin subtitleSecond-typeface() {
  font-size: px-to-rem(14px);
  font-weight: 700;
  line-height: px-to-rem(24px);
  letter-spacing: px-to-rem(0.1px);
}

@mixin subtitleThird-typeface() {
  font-size: px-to-rem(12px);
  font-weight: 700;
  line-height: px-to-rem(18px);
  letter-spacing: px-to-rem(0.1px);
}

@mixin overline-typeface() {
  font-size: px-to-rem(12px);
  font-weight: 700;
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(1.5px);
}

@mixin placeholder-typeface() {
  @extend subtitleSecond-typeface !optional;
  font-weight: 700;
  color: $textOnBackgroundUltraLight;
}

//=====================
//       Buttons
//=====================

@mixin button-typeface() {
  font-size: px-to-rem(16px);
  font-weight: 700;
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(1.25px);
}
